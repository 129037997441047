<template>
    <div>
        <form-wizard
            :color="is_loading.submit ? '#b0a8ff' : '#38B22D'"
            :title="null"
            :subtitle="null"
            shape="square"
            :finish-button-text="is_loading.submit ? 'Submitting...' : 'Apply'"
            class="mb-3"
            @on-complete="formSubmitted()"
        >
            <!-- regularization details tab -->
            <tab-content
                title="Regularization Details"
                icon="feather icon-file-text"
                :before-change="validationForm"
            >
                <validation-observer ref="accountRules" tag="form">
                    <b-form
                        autocomplete="off"
                        id="regularization-form"
                        class="p-2"
                    >
                        <b-row>
                            <b-col md="6">
                                <Calendar
                                    :user-hashid.sync="userHashid"
                                    v-if="renderComponent"
                                />
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Dates" label-for="dates">
                                    <flat-pickr
                                        v-model="selectedDates"
                                        :config="dateConfig"
                                        class="form-control"
                                        name="dates"
                                        placeholder="Select Dates to Regularize "
                                    />
                                </b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Start From"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Start From"
                                        label-for="from_time"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                    >
                                        <v-select
                                            v-model="data_local.from_time"
                                            :dir="
                                                $store.state.appConfig.isRTL
                                                    ? 'rtl'
                                                    : 'ltr'
                                            "
                                            :options="times"
                                            :clearable="false"
                                            :searchable="false"
                                            input-id="from_time"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            placeholder="Select Start From.."
                                        />
                                        <small class="text-danger">{{
                                            errors[0]
                                        }}</small>
                                    </b-form-group>
                                </validation-provider>
                                <validation-provider
                                    #default="{ errors }"
                                    name="End At"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="End At"
                                        label-for="to_time"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                    >
                                        <v-select
                                            v-model="data_local.to_time"
                                            :dir="
                                                $store.state.appConfig.isRTL
                                                    ? 'rtl'
                                                    : 'ltr'
                                            "
                                            :options="times"
                                            :clearable="false"
                                            :searchable="false"
                                            input-id="to_time"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            placeholder="Select End At.."
                                        />
                                        <small class="text-danger">{{
                                            errors[0]
                                        }}</small>
                                    </b-form-group>
                                </validation-provider>

                                <b-form-group
                                    label="Reason for the Regularization"
                                    label-for="reason"
                                >
                                    <b-form-textarea
                                        id="reason"
                                        v-model="data_local.reason"
                                        placeholder="Enter your reason for this regularization application"
                                        rows="3"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-form-group
                                v-for="(item, index) in customFields"
                                :key="item.id"
                                class="col-md-6"
                            >
                                <!-- #default="{ errors }" -->
                                <validation-provider
                                    :name="item.name"
                                    rules=""
                                    #default="validationContext"
                                >
                                    <b-form-group
                                        v-if="item.type == 'number'"
                                        :label="item.label"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                            :id="item.name"
                                            :name="`custom_fields_data[${item.name}-${item.id}]`"
                                            :value="
                                                data_local
                                                    .get_customfields_data[
                                                    'field_' + item.id
                                                ]
                                            "
                                            :placeholder="item.label"
                                            type="number"
                                            :state="
                                                getValidationState(
                                                    validationContext
                                                )
                                            "
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        v-if="item.type == 'text'"
                                        :label="item.label"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                            :id="item.name"
                                            :name="`custom_fields_data[${item.name}-${item.id}]`"
                                            :value="
                                                data_local
                                                    .get_customfields_data[
                                                    'field_' + item.id
                                                ]
                                            "
                                            :placeholder="item.label"
                                            type="text"
                                            :state="
                                                getValidationState(
                                                    validationContext
                                                )
                                            "
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        v-if="item.type == 'url'"
                                        :label="item.label"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                            :id="item.name"
                                            :name="`custom_fields_data[${item.name}-${item.id}]`"
                                            :value="
                                                data_local
                                                    .get_customfields_data[
                                                    'field_' + item.id
                                                ]
                                            "
                                            :placeholder="item.label"
                                            type="url"
                                            :state="
                                                getValidationState(
                                                    validationContext
                                                )
                                            "
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        v-if="item.type == 'rating'"
                                        :label="item.label"
                                        label-for="input-1"
                                    >
                                        <div class="d-flex align-items-center">
                                            <star-rating
                                                v-model="
                                                    data_local
                                                        .get_customfields_data[
                                                        'field_' + item.id
                                                    ]
                                                "
                                                :show-rating="false"
                                                :star-size="30"
                                            ></star-rating>
                                            <span
                                                v-if="
                                                    data_local
                                                        .get_customfields_data[
                                                        'field_' + item.id
                                                    ]
                                                "
                                                :class="`rating-btn btn ${
                                                    ratingdescription[
                                                        data_local
                                                            .get_customfields_data[
                                                            'field_' + item.id
                                                        ]
                                                    ].class
                                                }`"
                                            >
                                                {{
                                                    ratingdescription[
                                                        data_local
                                                            .get_customfields_data[
                                                            "field_" + item.id
                                                        ]
                                                    ].text
                                                }}</span
                                            >
                                            <input
                                                type="hidden"
                                                v-model="
                                                    data_local
                                                        .get_customfields_data[
                                                        'field_' + item.id
                                                    ]
                                                "
                                                :name="`custom_fields_data[${item.name}-${item.id}]`"
                                            />
                                        </div>
                                    </b-form-group>

                                    <b-form-group
                                        v-if="item.type == 'password'"
                                        :label="item.label"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                            :id="item.name"
                                            :name="`custom_fields_data[${item.name}-${item.id}]`"
                                            :value="
                                                data_local
                                                    .get_customfields_data[
                                                    'field_' + item.id
                                                ]
                                            "
                                            :placeholder="item.label"
                                            type="password"
                                            :state="
                                                getValidationState(
                                                    validationContext
                                                )
                                            "
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        v-if="item.type == 'textarea'"
                                        :label="item.label"
                                        label-for="input-1"
                                    >
                                        <b-form-textarea
                                            :name="`custom_fields_data[${item.name}-${item.id}]`"
                                            :value="
                                                data_local
                                                    .get_customfields_data[
                                                    'field_' + item.id
                                                ]
                                            "
                                            trim
                                            :placeholder="item.label"
                                        />
                                    </b-form-group>
                                    <b-form-group
                                        v-if="item.type == 'date'"
                                        :label="item.label"
                                        label-for="input-1"
                                    >
                                        <flat-pickr
                                            :config="{ dateFormat: 'Y-m-d' }"
                                            :name="`custom_fields_data[${item.name}-${item.id}]`"
                                            :value="
                                                data_local
                                                    .get_customfields_data[
                                                    'field_' + item.id
                                                ]
                                            "
                                            class="form-control"
                                            placeholder="Select Date.."
                                        />
                                    </b-form-group>

                                    <small class="text-danger">{{
                                        validationContext.errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-row>
                    </b-form>
                </validation-observer>
            </tab-content>
        </form-wizard>
    </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroup,
    VBTooltip,
    BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted, watch, watchEffect } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import store from "@/store";
import regularizationStoreModule from "../regularizationStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import { shift } from "postcss-rtl/lib/affected-props";
import { isToday } from "@/@core/utils/utils";
import moment from "moment";
import Calendar from "../Calendar.vue";
import { bus } from "@/main.js";



Vue.use(VueCookies);

export default {
    components: {
        flatPickr,
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BForm,
        BFormCheckbox,
        BInputGroupAppend,
        BInputGroup,
        vSelect,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        StarRating,
        flatPickr,
        Calendar,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            userHashid: this.$cookies.get("userData")["hashid"],
            renderComponent: true,
            ratingdescription: [
                {
                    text: "Below Average",
                    class: "star-poor",
                },
                {
                    text: "Below Average",
                    class: "star-poor",
                },
                {
                    text: "Average",
                    class: "star-belowAverage",
                },
                {
                    text: "Good",
                    class: "star-average",
                },
                {
                    text: "Very Good",
                    class: "star-good",
                },
                {
                    text: "Excellent",
                    class: "star-excellent",
                },
            ],
            is_loading: {
                submit: false,
            },
            redirectRegularization: "",
            data_local: {
                title: "",
                reason: "",
                from_time:"",
                to_time:"",
                get_customfields_data: [],
            },
            customFields: [],
            userData: this.$cookies.get("userData"),
            quarterHours: ["00", "15", "30", "45"],
            times: [],
            fromDateValid: false,
            toDateValid: false,
            holidays: [],
            dateConfig: {
                mode: "multiple",
                dateFormat: "Y-m-d",
                disable: [
                    function (date) {
                        return date.getDay() === 0;
                    },
                ],
                maxDate: "today",
            },
            userData: this.$cookies.get("userData"),
            selectedDates: [],
        };
    },

    setup() {
        const USER_APP_STORE_MODULE_NAME = "app-regularizations";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
            store.registerModule(
                USER_APP_STORE_MODULE_NAME,
                regularizationStoreModule
            );
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME))
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });
        const { refFormObserver, getValidationState, resetForm } =
            formValidation();

        return {
            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
    created() {
        let paramsDates = this.$route.query.dates
            ? this.$route.query.dates.replaceAll("-", " ")
            : "";
        let newArray = paramsDates.split(",  ");

        this.data_local.dates = this.formatDatesArray(newArray);

        this.getCustomFields();
        for (var i = 0; i < 24; i++) {
            for (var j = 0; j < 4; j++) {
                if (i < 10) {
                    this.times.push("0" + i + ":" + this.quarterHours[j]);
                } else {
                    this.times.push(i + ":" + this.quarterHours[j]);
                }
            }
        }
        
        this.data_local.from_time =
          this.userData.shift_details.session_1_start_from.slice(0, -3);
        this.data_local.to_time =
          this.userData.shift_details.session_2_end_at.slice(0, -3);

          bus.$on('dataDates', (data) => {
            this.selectedDates = data.value
              });
    },
    methods: {
        formatDatesArray: function (value) {
            if (value) {
                var myStringArray = value;
                var outputArray = [];
                for (var i = 0; i < myStringArray.length; i++) {
                    outputArray.push(
                        moment(String(myStringArray[i])).format(
                            "YYYY[-]MM[-]DD"
                        )
                    );
                }
                var outputString = outputArray.join(", ");
                return outputString;
            }
        },
        formSubmitted() {
            const self = this;
            const formData = new FormData(
                document.getElementById("regularization-form")
            );

            formData.append("reason", self.data_local.reason);
            formData.append("to_time", self.data_local.to_time);
            formData.append("from_time", self.data_local.from_time);

            this.is_loading.submit = true;
            this.$store
                .dispatch("app-regularizations/addRegularization", formData)
                .then((res) => {
                    // Add this regularization to NHR
                    this.is_loading.submit = false;
                    this.$router.push("/regularize-applications");
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Regularization Added",
                            icon: "BellIcon",
                            variant: "success",
                            text: res.data.message,
                        },
                    });
                })
                .catch((error) => {
                    this.is_loading.submit = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Oops! Regularization Adding Failed",
                            icon: "BellIcon",
                            variant: "danger",
                            text: error,
                        },
                    });
                });
        },
        checkToDate(selectedDate) {
            if (
                this.holidays.includes(selectedDate) &&
                typeof selectedDate !== "undefined"
            ) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title:
                            "The 'To' selected day '" +
                            selectedDate +
                            "' is already Holiday for your shift",
                        icon: "BellIcon",
                        variant: "warning",
                    },
                });
                this.toDateValid = false;
            } else {
                if (typeof selectedDate !== "undefined") {
                    this.toDateValid = true;
                }
            }
        },
        fetchUserHolidays() {
            this.isloading = true;
            this.$store
                .dispatch("app-leave_applications/fetchUserHolidays", {
                    year: new Date().getFullYear(),
                    shift: this.userData.shift_details.id,
                })
                .then((response) => {
                    var holidays = response.data.data;
                    for (let i = 0; i < holidays.length; i++) {
                        this.holidays.push(holidays[i].date);
                    }
                    this.isloading = false;
                })
                .catch((error) => {
                    this.isloading = false;
                });
        },
        validationForm() {
            const self = this;

            return new Promise((resolve, reject) => {
                this.$refs.accountRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },
        getCustomFields() {
            const self = this;
            self.$store
                .dispatch("app-regularizations/getCustomFields")
                .then((res) => {
                    self.customFields = res.data.data;
                    self.regularizationUrgencyStatusOption =
                        res.data.meta.urgency_status;
                    self.regularizationStatusOption = res.data.meta.status;
                    self.regularizationTypeOption = res.data.meta.type;
                })
                .catch((error) => {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Oops! Custom Fields Getting Failed",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    });
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
    display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
    position: absolute;
    right: 180px;
    bottom: 60px;
    border: solid 1px;
}
span.rating-btn {
    width: 130px;
    margin-left: 5px;
    padding: 2px;
    color: #fff;
    border-radius: 2px;
    font-size: 13px;
    text-align: center;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    line-height: 25px;
}
.star-belowAverage {
    background: #f5c357;
}
.star-poor {
    background: #bababa;
}
.star-average {
    background: #f3d23e;
}
.star-good {
    background: #c1d759;
}
.star-excellent {
    background: #48964d;
}
</style>
